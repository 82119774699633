// This custom style is used for the custom author section in the admin
// initiative edition form
.author-row {
  display: flex;
  align-items: center;
  gap: .7rem;
  margin-bottom: .7rem;

  .author--avatar {
    img {
      max-width: 3.5em;
      border-radius: .5rem;
    }
  }

  .author--name {
    font-size: 110%;
    font-weight: bold;
  }

  .author--actions {
    .button {
      margin: 0;
    }
  }
}