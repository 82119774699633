// This custom style is used to personalize the vote support progress bars
// for initiatives and suggestions
$global_scope_goal_not_reached_color: #d54129 !default;

.progress__bar{
  flex: .8;

  .progress__bar__title{
    color: $muted;
    font-size: .875rem;

    .progress__bar__number{
      @extend .card__support__number;
    }
  }

  .progress__bar__subtitle{
    display: none;
  }

  .progress__bar__bar{
    background-color: $white;
    height: 6px;
    display: flex;
    margin-bottom: 0;

    .progress__bar__bar--complete{
      border-radius: 0;
      background-color: $actions;
      height: 100%;
    }

    .progress__bar__bar--incomplete{
      background-color: rgba($muted, .3);
      height: 100%;
      margin-left: 1px;
    }

    .global__scope__goal__not__reached{
      background-color: $global_scope_goal_not_reached_color;
    }
  }

  &.progress__bar--vertical{
    text-align: center;
    width: 90%;
    margin: 0 auto;

    .progress__bar__title{
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }

    .progress__bar__subtitle{
      @extend .progress__bar__title;

      display: block;
      font-size: .875rem;
      margin: .5rem 0 1rem;
    }

    .progress__bar__number{
      display: inline-block;
      font-weight: 400;
      font-size: 3rem;
      line-height: 1;
    }

    .progress__bar__text{
      @extend .extra__suport-text;

      font-weight: 600;
      text-transform: uppercase;
    }
  }

  &.progress__bar--horizontal{
    margin-bottom: .35rem;
  }
}
