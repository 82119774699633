// This custom style is used for customizing the initiative creation
// "fill data" step form
label[for="initiative_no_signature"] {
  font-weight: bold;
  margin-bottom: 1rem;
}

fieldset.attachments_container legend {
  font-weight: bold;
}

a[href^="/initiatives/i-"] {
  .card__image {
    // Images should not appear on initiative cards
    display: none!important;
  }
}